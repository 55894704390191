*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
    position: absolute;
      right: 24%;
      top: 20%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#contact {
  padding: 40px 0 0 20px;
}

.page {
  height: auto!important;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

div {
  position: relative;
}

.number {
  width: 21px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.number div {
  -webkit-flex: 1 0 33.33333%;
          flex: 1 0 33.33333%;
  background-color: red;
}

.number .top-left,
.number .top-right,
.number .bottom-left,
.number .bottom-right {
  -webkit-flex: 0 0 4px;
          flex: 0 0 4px;
}
.number .top-left::before,
.number .top-right::before,
.number .bottom-left::before,
.number .bottom-right::before {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  left: 0;
  top: -4px;
  background-color: transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-bottom: 2px solid red;
}
.number .top-left::after,
.number .top-right::after,
.number .bottom-left::after,
.number .bottom-right::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  background-color: transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-top: 2px solid red;
  left: 0;
  bottom: -4px;
}

.number .top-left,
.number .top-right {
  height: 10px;
  margin: 5px 0 1px;
}

.number .bottom-left,
.number .bottom-right {
  height: 10px;
  margin: 1px 0 5px;
}

.number .mid {
  background-color: red;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: calc(100% - 8px);
          flex-basis: calc(100% - 8px);
  border: 0;
  height: 4px;
  margin: 0 4px;
}

.number .top,
.number .bottom {
  background-color: red;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-flex-shrink: 1;
          flex-shrink: 1;
  -webkit-flex-basis: calc(100% - 16px);
          flex-basis: calc(100% - 16px);
  border: 0;
  height: 4px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.number .top::before,
.number .mid::before,
.number .bottom::before {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  background-color: transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-right: 2px solid red;
  left: -4px;
  top: 0;
}
.number .top::after,
.number .mid::after,
.number .bottom::after {
  content: '';
  display: block;
  position: absolute;
  height: 0;
  width: 0;
  background-color: transparent;
  border-top: 2px solid transparent;
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  border-bottom: 2px solid transparent;
  border-left: 2px solid red;
  right: -4px;
  top: 0;
}

.number .top {
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.number .bottom {
  -webkit-align-self: flex-end;
          align-self: flex-end;
}

.number.zero .mid {
  opacity: 0;
}
.number.one .top-left,
.number.one .top,
.number.one .mid,
.number.one .bottom-left,
.number.one .bottom {
  opacity: 0;
}
.number.two .top-left,
.number.two .bottom-right {
  opacity: 0;
}
.number.three .top-left,
.number.three .bottom-left {
  opacity: 0;
}
.number.four .top,
.number.four .bottom-left,
.number.four .bottom {
  opacity: 0;
}
.number.five .top-right,
.number.five .bottom-left {
  opacity: 0;
}
.number.six .top-right {
  opacity: 0;
}
.number.seven .top-left,
.number.seven .mid,
.number.seven .bottom-left,
.number.seven .bottom {
  opacity: 0;
}
.number.nine .bottom-left {
  opacity: 0;
}

header {
  height: 60px;
  width: 100%;
  background-color: #ddd;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.number {
  margin-left: 5px;
}

.screen {
  background-color: black;
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.flags,
.status,
.timer {
  height: 50px;
  background-color: #ddd;
  border: 2px solid;
  border-top-color: #eee;
  border-right-color: #aaa;
  border-left-color: #eee;
  border-bottom-color: #aaa;
}

.flags {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 6px;
}
.status {
  width: 50px;
  font-size: 24px;
}
.timer {
  -webkit-flex: 1 1;
          flex: 1 1;
  margin: 6px;
}

.frame {
  padding: 6px;
  background: #ddd;
  border: 3px solid;
  border-top-color: #eee;
  border-right-color: #aaa;
  border-left-color: #eee;
  border-bottom-color: #aaa;
}

.toolbar {
  height: 30px;
  background: blue;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 6px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.toolbar .button {
  -webkit-flex: 0 1;
          flex: 0 1;
  border: 0;
  color: white;
  font-size: 11px;
  margin-right: 12px;
  font-family: Verdana;
  border-bottom: 1px solid;
  background: transparent;
}

.toolbar .button.button-right {
  margin: 0 0 0 auto;
  -webkit-flex: 0 1;
          flex: 0 1;
  border: none;
  width: 17px;
  height: 17px;
}

.toolbar .button.button-right img {
  width: 17px;
  height: 17px;
}

.toolbar .button:hover,
.toolbar .button:active,
.toolbar .button:focus {
  background: transparent !important;
}

.modal {
  display: none;
  position: absolute;
  background: #ddd;
  color: black;
  font-size: 11px;
  z-index: 1;
  top: 35px;
  border: 1px solid black;
  left: 0;
  padding: 6px;
}

.modal ul {
  list-style: none;
}

.modal ul li {
  margin: 0 0 6px;
}

.modal.visible {
  display: block;
}

input[type='radio'] {
  margin-right: 6px;
}

.page {
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.grid {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.row {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.column {
  width: 24px;
  height: 24px;
  display: block;
}

button {
  outline: none;
  -webkit-appearance: none;
  background-color: #ddd;
  border: 0;
}

.button {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid;
  height: 100%;
  width: 100%;
  border: 2px solid;
  border-top-color: #eee;
  border-right-color: #aaa;
  border-left-color: #eee;
  border-bottom-color: #aaa;
}

button:not(.inactive):not(.show):not(.flag):active {
  border-top-color: #aaa;
  border-right-color: #eee;
  border-left-color: #aaa;
  border-bottom-color: #eee;
}

.button:not(.inactive):not(.show):hover {
  background-color: #ccc;
}

.button.show {
  background-color: white;
  border: 2px solid #eee;
}

.button.bomb {
  background-color: red;
}

